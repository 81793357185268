import { format } from "date-fns";
import { sumBy } from "lodash";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Allocation, Event } from "../data/types";
import { encryptStorage } from "../utils/storage";

export const FurtherInfos: React.FC<{
  event: Event;
}> = ({ event }) => {
  const roles: {
    name: string;
    data: {
      artistId: string;
      showSalary?: boolean;
    };
  }[] = encryptStorage.getItem("roles") ?? [];

  const [guests, setGuests] = useState<Event["tickets"]["reservations"]>();

  useEffect(() => {
    const guestList = event.tickets?.reservations;
    if (guestList?.length > 0) setGuests(guestList);
  }, [event.tickets?.reservations]);

  return (
    <>
      {event.artistExtraInfo ? (
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Für den Künstler</Card.Title>

            {event.artistExtraInfo}
          </Card.Body>
        </Card>
      ) : null}
      {event.tickets ? (
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Ticket Infos</Card.Title>
            {event.tickets.preSale.systems.length ? (
              <div>
                Gesamt: {sumBy(event.tickets.preSale.systems, "capacity")}
              </div>
            ) : null}
            {event.tickets?.preSale.total.sold ? (
              <div>
                Verkauft: {event.tickets?.preSale.total.sold}
                {event.tickets?.preSale.total.utilization ? (
                  <span>
                    {" "}
                    (
                    {(event.tickets?.preSale.total.utilization * 100).toFixed(
                      2
                    )}{" "}
                    %)
                  </span>
                ) : null}
              </div>
            ) : null}
            {event.tickets.preSale.total.lastSaleAt ? (
              <div>
                Stand:{" "}
                {format(
                  new Date(event.tickets.preSale.total.lastSaleAt),
                  "dd.MM.Y"
                )}
              </div>
            ) : null}
            {event.tickets?.notes ? <div>{event.tickets?.notes}</div> : null}
          </Card.Body>
        </Card>
      ) : null}

      {event.merchandise?.note ? (
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Merchandise</Card.Title>
            <div>{event.merchandise?.note}</div>
            {event.merchandise?.note ? <div>Gebührenpflichtig</div> : null}
          </Card.Body>
        </Card>
      ) : null}

      {event.cast?.[0]?.salaryModel &&
      roles.find(
        (r) =>
          r.data?.artistId === event.cast?.[0]?.artist?.id && r.data?.showSalary
      ) ? (
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Gage</Card.Title>

            {event.cast.map((cast, index) => {
              if (
                !roles.find(
                  (r) =>
                    r.data?.artistId === cast.artist?.id && r.data?.showSalary
                )
              )
                return null;
              return (
                <div key={index}>
                  <h6>{cast.artist?.stageName}</h6>
                  {cast.salaryModel?.guaranteed ? (
                    <div>Garantie: {cast.salaryModel.guaranteed} €</div>
                  ) : null}
                  {cast.salaryModel?.fix ? (
                    <div>Fixum: {cast.salaryModel.fix} €</div>
                  ) : null}
                  {cast?.salaryModel?.deals?.length ? (
                    <div className="mt-3">
                      <h6>Deals:</h6>
                      <ul>
                        {cast.salaryModel?.deals.map((deal, index) => (
                          <li key={index}>
                            {deal.amount * 100} % ab {deal.condition} €
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  {cast.salaryModel?.note ? (
                    <div>{cast.salaryModel.note}</div>
                  ) : null}
                </div>
              );
            })}
          </Card.Body>
        </Card>
      ) : null}
      {guests
        ? guests.map((guest, gIndex) => (
            <Card className="mb-3" key={gIndex}>
              <Card.Body>
                <Card.Title>{guest.name}</Card.Title>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Anzahl</th>
                      <th>Infos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {guest.allocations?.map(
                      (allocation: Allocation, index: number) => (
                        <tr key={index}>
                          <td>{allocation.name}</td>
                          <td>{allocation.sold}</td>
                          <td>{allocation.notes}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          ))
        : null}
    </>
  );
};
