import { format } from "date-fns";
import { Perspective } from "../data/types";

export const defaultPerspective: Perspective = {
  pagination: {
    size: 25,
    number: 0,
  },
  filter: {
    dateStart: format(new Date(), "yyyy-MM-dd"),
  },
};
