import { Button, ButtonProps } from "react-bootstrap";

export const TableButton: React.FC<ButtonProps> = (props) => (
  <Button
    variant="link"
    style={{
      color: "black",
      textDecoration: "none",
      padding: 0,
      width: "100%",
      height: "100%",
    }}
    {...props}
  >
    {props.children}
  </Button>
);
