import React, { useEffect, useState } from "react";
import {
  Card,
  CloseButton,
  Container,
  Ratio,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  ClockFill,
  Files,
  GeoAltFill,
  Globe,
  InfoCircleFill,
  PersonCircle,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Communication } from "../components/Communications";
import { Schedules } from "../components/Schedule";
import { formatDe } from "../lib/datetime";
import { request } from "../lib/api";
import { Contact } from "../components/Contact";
import { FileList } from "../components/FileList";
import { Message, Perspective, File, Attendee } from "../data/types";
import { TravelBlock } from "../components/Travel";
import { AddressBlock } from "../components/AddressBlock";
import { Offline } from "../components/Offline";
import { FurtherInfos } from "../components/FurtherInfos";
import { sortBy } from "lodash";
import { encryptStorage } from "../utils/storage";

const EventDetail: React.FC<{
  setResponseMessage: (message: Message) => void;
}> = ({ setResponseMessage }) => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isOffline, setIsOffline] = useState(false);
  const [event, setEvent] = useState<any>(undefined);
  const [files, setFiles] = useState<any>(undefined);
  const perspective: Perspective | undefined =
    encryptStorage.getItem("perspective") ?? undefined;

  useEffect(() => {
    const currentEvent: any =
      encryptStorage.getItem("currentEvent") ?? undefined;

    if (currentEvent) {
      setIsLoading(false);
      setEvent(currentEvent.eventData);
      // console.log(currentEvent.eventData);

      encryptStorage.setItem(
        "perspective",
        JSON.stringify({
          activeKey: undefined,
        })
      );
    }

    // console.log(currentEvent.eventData);

    const instance = encryptStorage.getItem("instance");
    request({
      endpoint: `${instance}/api/assets?foreignKeyObjectId=${currentEvent.eventData.id}`,
      method: "GET",
    }).then(async (res) => {
      if (res.ok) {
        const data = await res.json();

        const filteredFiles = data.content.filter((d: File) => d.appAvailable);
        setFiles(filteredFiles);
      } else {
        if (navigator.onLine && res.status === 401) {
          setResponseMessage({
            show: true,
            error: true,
            message:
              "Daten konnten nicht geladen werden, du musst dich neu einloggen",
          });
          setTimeout(
            () => navigate(`${process.env.REACT_APP_BASEPATH}login`),
            500
          );
        } else if (!navigator.onLine && currentEvent) {
          setIsOffline(true);
        } else {
          setResponseMessage({
            show: true,
            error: true,
            message: "Es ist ein Fehler aufgetreten",
          });
          setTimeout(
            () => navigate(`${process.env.REACT_APP_BASEPATH}login`),
            500
          );
        }
      }
    });
  }, [navigate, setResponseMessage]);

  return (
    <Container className={`p-0 mb-4 rounded-3`}>
      {isOffline ? <Offline /> : null}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <div>
            <Spinner animation="border" />
          </div>
          <div className="mb-4">Event wird geladen</div>
        </div>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="titleContainer">
              <div className="eventDate">
                {formatDe(new Date(event.dateOfEvent), "E P")}
              </div>
              <div className="title">{`${`${
                event.tour?.title ?? event.title ?? ""
              }
                ${
                  event.cast?.[0]?.artist?.stageName
                    ? ` (${event.cast[0].artist.stageName})`
                    : ""
                }
              `}`}</div>
              {event.subtitle && <div>{event.subtitle}</div>}
            </div>
            <CloseButton
              onClick={() => navigate(`${process.env.REACT_APP_BASEPATH}`)}
            />
          </div>

          <Tabs
            onSelect={(activeKey: any) => {
              if (activeKey) {
                encryptStorage.setItem(
                  "perspective",
                  JSON.stringify({
                    activeKey: activeKey,
                  })
                );
              }
            }}
            defaultActiveKey={perspective?.activeKey}
            id="eventDetailTab"
            className="mb-0"
          >
            {event.venue && event.venue?.id ? (
              <Tab
                eventKey="location"
                title={<GeoAltFill />}
                tabClassName="eventDetailTab"
              >
                <Card>
                  <Card.Body>
                    <Card.Title>Veranstaltungsort</Card.Title>
                    <AddressBlock
                      label={event.venue?.name}
                      address={event.venue?.address}
                    />

                    {!isOffline ? (
                      <div className="mt-3">
                        <Ratio>
                          <iframe
                            title="Map"
                            src={`https://www.google.com/maps?&q=${
                              event.venue?.name
                            } ${event.venue?.address?.street} ${
                              event.venue?.address?.zipcode
                            } ${
                              event.venue?.address?.city
                            } ${event.venue?.address?.country?.toUpperCase()}&output=embed`}
                          />
                        </Ratio>
                      </div>
                    ) : null}
                  </Card.Body>
                </Card>
              </Tab>
            ) : null}

            <Tab
              eventKey="contacts"
              title={<PersonCircle />}
              tabClassName="eventDetailTab"
            >
              {event.productionPhone ? (
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Produktionsnummer</Card.Title>
                    <Communication
                      communicationtype="PHONE"
                      communicationvalue={event.productionPhone}
                    />
                  </Card.Body>
                </Card>
              ) : null}

              {event.projectManager ? (
                <Contact
                  title="Projektleiter/Örtl. Durchführer"
                  contact={event.projectManager}
                />
              ) : null}

              {event.contactPerson ? (
                <Contact
                  title="Ansprechpartner"
                  contact={event.contactPerson}
                />
              ) : null}

              {event.organizer ? (
                <Contact title="Veranstalter" contact={event.organizer} />
              ) : null}

              {event.tourCompanion ? (
                <Contact title="Tourbegleitung" contact={event.tourCompanion} />
              ) : null}

              {event.attendees && event.attendees.length
                ? sortBy(event.attendees, "type").map(
                    (attendee: Attendee, index: number) =>
                      attendee.contact ? (
                        <Contact
                          key={index}
                          title={`${
                            attendee.type === "TOURING"
                              ? "Touring Crew: "
                              : attendee.type === "LOCAL"
                              ? "Local Crew: "
                              : ""
                          }
                      ${attendee.task}`}
                          contact={attendee.contact}
                        />
                      ) : null
                  )
                : null}

              {event.contactPersonInternalUser?.contact ? (
                <Contact
                  title="Agent"
                  contact={event.contactPersonInternalUser.contact}
                />
              ) : null}

              {event.cast[0].artist?.contact ? (
                <Contact
                  title="Künstler"
                  contact={event.cast[0].artist?.contact}
                />
              ) : null}
            </Tab>

            <Tab
              eventKey="schedule"
              title={<ClockFill />}
              tabClassName="eventDetailTab"
            >
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Ablauf</Card.Title>
                  <Schedules event={event} />
                </Card.Body>
              </Card>
            </Tab>
            {event.travel ? (
              <Tab
                eventKey="travel"
                title={<Globe />}
                tabClassName="eventDetailTab"
              >
                <TravelBlock travel={event.travel} />
              </Tab>
            ) : null}
            <Tab
              eventKey="info"
              title={<InfoCircleFill />}
              tabClassName="eventDetailTab"
            >
              <FurtherInfos event={event} />
            </Tab>

            {files && files.length > 0 ? (
              <Tab
                eventKey="files"
                title={<Files />}
                tabClassName="eventDetailTab"
              >
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Dateien</Card.Title>
                    <FileList files={files} />
                  </Card.Body>
                </Card>
              </Tab>
            ) : null}
          </Tabs>
        </>
      )}
    </Container>
  );
};

export default EventDetail;
