import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { XCircle } from "react-bootstrap-icons";
import Pagination from "react-bootstrap/Pagination";
import { Perspective } from "../data/types";
import { defaultPerspective } from "../lib/defaultPerspective";

export const TableFilter: React.FC<{
  perspective: Perspective;
  setPerspective: React.Dispatch<React.SetStateAction<Perspective>>;
  loadData: (perspective: Perspective) => void;
  setLocationFilter: (value: string) => void;
}> = ({ perspective, setPerspective, loadData, setLocationFilter }) => {
  // console.log(perspective);
  const [locationValue, setLocationValue] = useState<string>(
    perspective.filter.location ?? ""
  );

  return (
    <div>
      <div className="tablePaginationContainer">
        <Pagination>
          <Pagination.First
            disabled={perspective.pagination.first}
            onClick={() =>
              loadData({
                ...perspective,
                pagination: {
                  number: 0,
                  size: perspective.pagination.size,
                },
              })
            }
          />
          <Pagination.Prev
            disabled={perspective.pagination.first}
            onClick={() =>
              loadData({
                ...perspective,
                pagination: {
                  number: perspective.pagination.number - 1,
                  size: perspective.pagination.size,
                },
              })
            }
          />
        </Pagination>

        <div>
          <Form.Select
            size="sm"
            name="pageSize"
            value={perspective.pagination?.size}
            onChange={(e) =>
              loadData({
                ...perspective,
                pagination: {
                  size: parseInt(e.target.value),
                  number: perspective.pagination.number,
                },
              })
            }
          >
            <option value="10">10 Datensätze</option>
            <option value="25">25 Datensätze</option>
            <option value="50">50 Datensätze</option>
            <option value="100">100 Datensätze</option>
          </Form.Select>
        </div>

        <Pagination>
          <Pagination.Next
            disabled={perspective.pagination.last}
            onClick={() =>
              loadData({
                ...perspective,
                pagination: {
                  number: perspective.pagination.number + 1,
                  size: perspective.pagination.size,
                },
              })
            }
          />
          <Pagination.Last
            disabled={perspective.pagination.last}
            onClick={() =>
              loadData({
                ...perspective,
                pagination: {
                  number: perspective.pagination.totalPages
                    ? perspective.pagination.totalPages - 1
                    : 0,
                  size: perspective.pagination.size,
                  last: true,
                  first: false,
                },
              })
            }
          />
        </Pagination>
      </div>

      <Row className="tableFilterContainer mt-3">
        <Col sm={12} md={6} className="mb-3">
          <InputGroup size="sm">
            <InputGroup.Text>Datum</InputGroup.Text>
            <Form.Control
              type="date"
              name="datepicker"
              placeholder="Datum wählen"
              value={perspective.filter?.dateStart}
              onChange={(e) => {
                setPerspective((prev) => ({
                  ...prev,
                  filter: {
                    dateStart: e.target.value,
                  },
                }));
                loadData({
                  ...perspective,
                  filter: {
                    dateStart: e.target.value,
                  },
                });
              }}
            />
          </InputGroup>
        </Col>

        <Col sm={12} md={6} className="mb-3">
          <InputGroup size="sm">
            <InputGroup.Text>Ort</InputGroup.Text>
            <Form.Control
              type="text"
              name="venue"
              placeholder="Ort suchen"
              value={locationValue}
              onChange={(e) => {
                setLocationFilter(e.target.value);
                setLocationValue(e.target.value);
              }}
            />

            <Button
              variant="secondary"
              onClick={() => {
                loadData(defaultPerspective);
                setPerspective(defaultPerspective);
                setLocationValue("");
              }}
            >
              <XCircle />
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};
