import React from "react";
// import { version } from "../../package.json";

import Container from "react-bootstrap/Container";
import { formatDe } from "../lib/datetime";
import { encryptStorage } from "../utils/storage";

// const date = "2023-02-22T23:00:00.000+00:00";
// const time = "19:30:00.000";
// console.log(new Date(date));
// console.log(formatDe(new Date(date), "P"));
// console.log(date.slice(0, 10));
// console.log(new Date(`${date.slice(0, 10)}T${time.slice(0, 5)}`));
// console.log(time.slice(0, 5));

// event.eventData?.start.slice(0, 5)
// console.log(parse("2023-02-22T23:00:00.000+00:00"));

const Info: React.FC = (event: any) => {
  return (
    <Container className="p-5 mb-4 bg-light rounded-3">
      <h1 className="header">Info</h1>
      <h2>co*pilot Reader</h2>
      <div>Version: {process.env.REACT_APP_VERSION}</div>
      <div>
        Host:{" "}
        {window.location.host.indexOf("localhost") === 0
          ? "http://"
          : "https://"}
        {window.location.host}
      </div>
      <div>Instanz: {encryptStorage.getItem("instance")}</div>
      <div>Benutzer: {encryptStorage.getItem("username")}</div>
      <h3 className="mt-3">Zeitzone</h3>
      <div>Zeitzone: {Intl.DateTimeFormat().resolvedOptions().timeZone}</div>
      <div>Lokale Uhrzeit: {new Date().toLocaleTimeString()}</div>
      <div>Deutsche Uhrzeit: {formatDe(new Date(), "H:m:ss")}</div>
    </Container>
  );
};

export default Info;
