import { Button, Table } from "react-bootstrap";
import { File } from "../data/types";
import { encryptStorage } from "../utils/storage";

export const FileList: React.FC<{
  files: any;
}> = ({ files }) => {
  const baseUrl = `${
    process.env.REACT_APP_API_ENDPOINT
  }${encryptStorage.getItem("instance")}`;

  const downloadPDF = (url: string, filename: string) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      if (this.status === 200) {
        var myBlob = this.response;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(myBlob);
        link.download = filename;
        link.click();
      }
    };
    xhr.send();
  };

  return (
    <Table responsive striped hover borderless>
      <tbody>
        {files.map((file: File) => {
          const fileSize: number = file.size
            ? parseInt((file.size / 1000).toFixed())
            : 0;
          return (
            <tr key={file.id}>
              {/* <td>
                <Button
                  variant="link"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    padding: 0,

                    height: "100%",
                  }}
                  onClick={() =>
                    window.open(`${baseUrl}/api/assets/${file.id}/download`)
                  }
                >
                  {file.filename}
                </Button>
              </td> */}
              <td>
                <Button
                  variant="link"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    padding: 0,

                    height: "100%",
                  }}
                  onClick={() =>
                    downloadPDF(
                      `${baseUrl}/api/assets/${file.id}/download`,
                      file.filename
                    )
                  }
                >
                  {file.filename}
                </Button>
              </td>
              <td style={{ width: 80, textAlign: "right" }}>
                {fileSize > 1000
                  ? `${(fileSize / 1000).toFixed(2)} MB`
                  : `${fileSize} kB`}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
