import { Card } from "react-bootstrap";
import { Travel } from "../data/types";
import { AddressBlock } from "./AddressBlock";
import { Communications } from "./Communications";
import { InfoBlock } from "./InfoBlock";

export const TravelBlock: React.FC<{
  travel: Travel;
}> = ({ travel }) => {
  return (
    <>
      {travel.journey?.route ? (
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Anreise</Card.Title>
            {/* <Card.Subtitle className="mb-2 text-muted">Route</Card.Subtitle> */}
            <div>{travel.journey?.route}</div>
            {travel.journey?.details ? (
              <InfoBlock>
                <h6>Zug - Flug - Rental Details:</h6>
                {travel.journey?.details}
              </InfoBlock>
            ) : null}
            {travel.journey?.venueTransfer ? (
              <InfoBlock>
                <h6>Anreise Veranstaltungsort:</h6>
                {travel.journey?.venueTransfer}
              </InfoBlock>
            ) : null}
            {travel.journey?.venueParking ? (
              <InfoBlock>
                <h6>Beladen/Parken:</h6>
                {travel.journey?.venueParking}
              </InfoBlock>
            ) : null}
            {travel.journey?.localTransfer ? (
              <InfoBlock>
                <h6>Lokaler Transfer:</h6>
                {travel.journey?.localTransfer}
              </InfoBlock>
            ) : null}
            {travel.journey?.note ? (
              <InfoBlock>
                <h6>Further Notes:</h6>
                {travel.journey?.note}
              </InfoBlock>
            ) : null}
          </Card.Body>
        </Card>
      ) : null}

      {travel.journey?.departure ? (
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Abreise</Card.Title>
            <div>{travel.journey?.departure}</div>
          </Card.Body>
        </Card>
      ) : null}

      {travel.hotel ? (
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Hotel</Card.Title>
            {travel.hotel?.dismissed ? <div>Entfällt</div> : null}
            {travel.hotel?.contact?.addresses?.[0] ? (
              <AddressBlock
                label={
                  travel.hotel?.contact?.company ??
                  travel.hotel?.contact?.firstName ??
                  "Hotel"
                }
                address={travel.hotel?.contact?.addresses?.[0]}
              />
            ) : null}

            <div>
              {travel.hotel?.contact?.options &&
              travel.hotel?.contact?.options?.length > 0 ? (
                <Communications
                  communications={travel.hotel?.contact?.options}
                />
              ) : null}
            </div>
            {travel.hotel?.checkIn ? (
              <InfoBlock>
                <span className="infoLabel">Checkin:</span>{" "}
                {travel.hotel.checkIn} -{" "}
                <span className="infoLabel">Checkout: </span>
                {travel.hotel.checkOut}
              </InfoBlock>
            ) : null}
            {travel.hotel?.venueDistance ? (
              <InfoBlock>
                <span className="infoLabel">Entfernung zum Venue: </span>
                {travel.hotel.venueDistance}
              </InfoBlock>
            ) : null}
            {travel.hotel?.details ? (
              <InfoBlock>
                <span className="infoLabel">Weitere Notizen:</span>{" "}
                {travel.hotel?.details}
              </InfoBlock>
            ) : null}
            {travel.hotel?.directions ? (
              <InfoBlock>
                <span className="infoLabel">Reisedetails:</span>{" "}
                {travel.hotel.directions}
              </InfoBlock>
            ) : null}
            {travel.hotel?.rooms?.entries?.length ? (
              <InfoBlock>
                <h6>Zimmerreservierungen:</h6>
                <ul>
                  {travel.hotel.rooms?.entries.map((room, index) => (
                    <li key={index}>
                      Anzahl: {room.amount} | Typ: {room.type}
                    </li>
                  ))}
                </ul>
              </InfoBlock>
            ) : null}
          </Card.Body>
        </Card>
      ) : null}
    </>
  );
};
