import React, { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import { events } from "./../data/events";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { formatDe } from "../lib/datetime";

import { Eye } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { TableButton } from "../components/Buttons";
// import { TableFilter } from "../components/TableFilter";
import { filter, omit } from "lodash";
import { Offline } from "../components/Offline";
import { TableFilter } from "../components/TableFilter";
import { Data, Message, Payload, Perspective } from "../data/types";
import { request } from "../lib/api";
import { defaultPerspective } from "../lib/defaultPerspective";
import { encryptStorage } from "../utils/storage";

const EventList: React.FC<{
  setResponseMessage: (message: Message) => void;
}> = ({ setResponseMessage }) => {
  const [events, setEvents] = useState<Payload | undefined>(undefined);
  let [eventsOrig, setEventsOrig] = useState<Payload | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isOffline, setIsOffline] = useState(false);
  let navigate = useNavigate();
  const [perspective, setPerspective] =
    useState<Perspective>(defaultPerspective);

  useEffect(() => {
    if (!navigator.onLine) setIsOffline(true);
  }, []);

  const loadData = useCallback(
    (perspective: Perspective) => {
      if (navigator.onLine) {
        const instance = encryptStorage.getItem("instance");
        const roles = encryptStorage.getItem("roles");
        if (roles && instance) {
          const artistIds = roles
            .filter((r: any) => r.name === "ARTIST")
            .map((r: any) => r.data.artistId)
            .join(",");
          if (!artistIds) {
            setResponseMessage({
              show: true,
              error: true,
              message:
                "Für dich sind keine Daten freigeschaltet. Wende dich an dein Management",
            });
          } else {
            request({
              endpoint: `${instance}/api/bookings/performances?artists=${artistIds}&eventStates=CONFIRMED&eventDateFrom=${
                perspective.filter.dateStart
              }&page.page=${perspective.pagination.number + 1}&page.size=${
                perspective.pagination.size
              }&page.sort=event.dateOfEvent`,
              method: "GET",
            }).then(async (res) => {
              if (res.ok) {
                let data = await res.json();
                // console.log(data);
                setIsLoading(false);
                encryptStorage.setItem("events", JSON.stringify(data));
                setEvents(data);
                setEventsOrig(data);
              } else if (res.status === 401) {
                setResponseMessage({
                  show: true,
                  error: true,
                  message: "Du bist nicht angemeldet oder nicht berechtigt",
                });
                setTimeout(
                  () => navigate(`${process.env.REACT_APP_BASEPATH}login`),
                  1000
                );
              } else {
                setResponseMessage({
                  show: true,
                  error: true,
                  message: "Es ist ein Fehler aufgetreten",
                });
              }
            });
          }
        } else {
          setResponseMessage({
            show: true,
            error: true,
            message: "Du bist nicht angemeldet",
          });
          setTimeout(
            () => navigate(`${process.env.REACT_APP_BASEPATH}login`),
            1000
          );
        }
      }
    },
    [navigate, setResponseMessage]
  );

  const setLocationFilter = (value: string) => {
    setEvents((prevEvents: any) => ({
      ...prevEvents,
      content: filter(
        eventsOrig?.content,
        (d: Data) =>
          d.eventData.venue.name?.toLowerCase().includes(value.toLowerCase()) ||
          d.eventData.venue?.address?.city
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          d.eventData.venue?.address?.zipcode
            ?.toLowerCase()
            .includes(value.toLowerCase())
      ),
    }));
  };

  useEffect(() => {
    if (isOffline) {
      const storedEvents = encryptStorage.getItem("events");
      setEvents(storedEvents ? JSON.parse(storedEvents) : []);
      setIsLoading(false);
    } else loadData(perspective);
  }, [isOffline, loadData, navigate, perspective, setResponseMessage]);

  const saveAndShowEvent = (event: any) => {
    encryptStorage.setItem("currentEvent", JSON.stringify(event));
    navigate(`event/${event.id}`);
  };

  // console.log(events?.content[3]);
  // console.log(events?.content[3].eventData.dateOfEvent);
  // console.log(new Date(events?.content[3].eventData.dateOfEvent));

  return (
    <Container className={`p-1 mb-4 bg-light rounded-3`}>
      {isOffline ? <Offline /> : null}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <div>
            <Spinner animation="border" />
          </div>
          <div className="mb-4">Events werden geladen</div>
        </div>
      ) : events?.content ? (
        <>
          {!isOffline ? (
            <TableFilter
              perspective={{
                ...perspective,
                pagination: omit(events, "content"),
              }}
              loadData={loadData}
              setPerspective={setPerspective}
              setLocationFilter={setLocationFilter}
            />
          ) : null}
          <Table responsive striped hover borderless>
            <tbody>
              {events.content
                .filter((data: Data) => data.eventData)
                .map((event: any) => (
                  <tr key={event.id}>
                    <td style={{ width: 80 }}>
                      <TableButton onClick={() => saveAndShowEvent(event)}>
                        {event.eventData?.start ? (
                          <div className="time">
                            {event.eventData?.start.slice(0, 5)}
                          </div>
                        ) : null}
                        <div className="month">
                          {formatDe(
                            new Date(event?.eventData?.dateOfEvent),
                            "LLL yy"
                          )}
                        </div>
                        <div className="date">
                          {formatDe(
                            new Date(event?.eventData?.dateOfEvent),
                            "dd"
                          )}
                        </div>
                        <div className="day">
                          {formatDe(
                            new Date(event?.eventData?.dateOfEvent),
                            "EEEEEE"
                          )}
                        </div>
                      </TableButton>
                    </td>
                    <td>
                      <TableButton onClick={() => saveAndShowEvent(event)}>
                        <div className="artist">
                          {event.artist.stageName} (
                          {event?.eventData?.tour?.title ??
                            event?.eventData?.title ??
                            ""}
                          )
                        </div>
                        <div className="location">
                          {event?.eventData?.venue.name}
                        </div>
                        <div className="locationAddress">
                          {event?.eventData?.venue?.address?.country?.toUpperCase()}{" "}
                          {event?.eventData?.venue?.address?.zipcode}{" "}
                          {event?.eventData?.venue?.address?.city}
                        </div>
                      </TableButton>
                    </td>
                    <td style={{ width: 40 }}>
                      <TableButton onClick={() => saveAndShowEvent(event)}>
                        <Eye />
                      </TableButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      ) : (
        "Keine Events vorhanden"
      )}
    </Container>
  );
};

export default EventList;
