import { format, setMinutes } from "date-fns";
import { Event, Schedule } from "../data/types";
import { sortBy } from "lodash";
import { Table } from "react-bootstrap";

enum ScheduleType {
  soundcheck = "Soundcheck",
  entrance = "Doors",
  start = "Beginn",
  getIn = "GetIn",
  end = "Ende",
}

const getSchdedulesFromEvent = (event: Event) => {
  let schedules: {
    name: ScheduleType | string;
    start: Date;
    end?: Date;
  }[] = [];
  const currentDate = new Date(event.dateOfEvent ?? "");

  if (event.getIn) {
    const time = event.getIn.split(":");
    const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    const getIn = setMinutes(currentDate, minutes);
    schedules.push({
      name: ScheduleType.getIn,
      start: getIn,
    });
  }
  if (event.soundcheck) {
    const time = event.soundcheck.split(":");
    const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    const soundcheck = setMinutes(currentDate, minutes);
    schedules.push({
      name: ScheduleType.soundcheck,
      start: soundcheck,
    });
  }
  if (event.entrance) {
    const time = event.entrance.split(":");
    const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    const entrance = setMinutes(currentDate, minutes);
    schedules.push({
      name: ScheduleType.entrance,
      start: entrance,
    });
  }
  if (event.start) {
    const time = event.start.split(":");
    const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    const start = setMinutes(currentDate, minutes);
    schedules.push({
      name: ScheduleType.start,
      start: start,
    });
  }
  if (event.end) {
    const time = event.end.split(":");
    const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    const end = setMinutes(currentDate, minutes);
    schedules.push({
      name: ScheduleType.end,
      start: end,
    });
  }

  if (event.schedule) {
    event.schedule.map((schedule: Schedule) =>
      schedule.start
        ? schedules.push({
            name: schedule.name,
            start: new Date(schedule.start),
            end: schedule.end !== null ? new Date(schedule.end) : undefined,
          })
        : null
    );
  }

  return sortBy(schedules, "start");
};

export const Schedules: React.FC<{
  event: Event;
}> = ({ event }) => {
  const schedules = getSchdedulesFromEvent(event);

  return (
    <Table>
      <tbody>
        {schedules.map((schedule) => (
          <SingleSchedule key={schedule?.name} schedule={schedule} />
        ))}
      </tbody>
    </Table>
  );
};

export const SingleSchedule: React.FC<{
  schedule: {
    name: string;
    start: Date;
    end?: Date;
  };
}> = (props) => {
  return (
    <tr>
      <td className="scheduleLabel">{props.schedule?.name}</td>
      <td className="scheduleTime">
        {format(new Date(props.schedule?.start), "d.MM.Y")}
      </td>
      <td className="scheduleTime">
        {format(new Date(props.schedule?.start), "H:mm")}
        {props.schedule?.end
          ? ` - ${format(new Date(props.schedule?.end), "H:mm")}`
          : null}{" "}
        Uhr
      </td>
    </tr>
  );
};
