import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

import Container from "react-bootstrap/Container";
import { Message } from "../data/types";
import { loginRequest } from "../lib/api";
import { encryptStorage } from "../utils/storage";

type FormData = {
  instance: string;
  username: string;
  password: string;
};

const Login: React.FC<{ setResponseMessage: (message: Message) => void }> = ({
  setResponseMessage,
}) => {
  const [formData, setFormData] = useState<FormData>({
    instance: encryptStorage.getItem("instance") ?? "",
    username: encryptStorage.getItem("username") ?? "",
    password: encryptStorage.getItem("password") ?? "",
  });

  const logIn = async () => {
    if (formData?.instance && formData?.username && formData?.password) {
      const body = new URLSearchParams({
        username: formData.username,
        password: formData.password,
      });
      try {
        let response = await loginRequest(formData?.instance, body);
        if (response.id) {
          // console.log(response);

          encryptStorage.setItem("instance", formData.instance);
          encryptStorage.setItem("username", formData.username);
          encryptStorage.setItem("password", formData.password);
          encryptStorage.setItem("roles", JSON.stringify(response.roles));
          setResponseMessage({
            show: true,
            success: true,
            message: "Erfolgreich angemeldet",
          });
          setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_BASEPATH}`;
          }, 1000);
        } else {
          setResponseMessage({
            show: true,
            error: true,
            message: "Anmeldung fehl geschlagen",
          });
        }
      } catch (e) {
        setResponseMessage({
          show: true,
          error: true,
          message: "Anmeldung fehl geschlagen",
        });
      }
    }
  };
  return (
    <Container className="p-5 mb-4 bg-light rounded-3">
      <h1 className="header">Login</h1>
      <Form>
        <Form.Group className="mb-3" controlId="formInstance">
          <Form.Label>Mandant</Form.Label>
          <Form.Control
            type="text"
            placeholder="Mandant eingeben"
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                instance: e.target.value,
              }))
            }
            value={formData.instance}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formUsername">
          <Form.Label>Benutznername</Form.Label>
          <Form.Control
            type="text"
            placeholder="Benutzername eingeben"
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                username: e.target.value,
              }))
            }
            value={formData.username}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Passwort eingeben"
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                password: e.target.value,
              }))
            }
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                logIn();
              }
            }}
            value={formData.password}
          />
        </Form.Group>

        <Button onClick={logIn}>Anmelden</Button>
      </Form>
    </Container>
  );
};

export default Login;
