import { Button, ButtonProps } from "react-bootstrap";
import { Envelope, Telephone } from "react-bootstrap-icons";
import { Option } from "../data/types";

export const Communications: React.FC<{
  communications: Option[];
}> = ({ communications }) => {
  return (
    <div className="mb-2">
      {communications.map((c, index) => (
        <div key={index}>
          {c.mobile ? (
            <Communication
              communicationtype="PHONE"
              communicationvalue={c.mobile}
            />
          ) : null}
          {c.phone ? (
            <Communication
              communicationtype="PHONE"
              communicationvalue={c.phone}
            />
          ) : null}
          {c.email ? (
            <Communication
              communicationtype="EMAIL"
              communicationvalue={c.email}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};
export const Communication: React.FC<
  ButtonProps & {
    communicationtype: "PHONE" | "EMAIL";
    communicationvalue: string;
  }
> = (props) => {
  const href =
    props.communicationtype === "PHONE"
      ? `tel:${props.communicationvalue}`
      : `mailto:${props.communicationvalue}`;
  return (
    <Button
      variant="link"
      style={{
        color: "black",
        textDecoration: "none",
        padding: 0,
        display: "flex",
      }}
      href={href}
      {...props}
    >
      <div className="communicationButton">
        {props.communicationtype === "PHONE" ? <Telephone /> : <Envelope />}
      </div>
      <div>{props.communicationvalue}</div>
    </Button>
  );
};
