import { encryptStorage } from "../utils/storage";

export async function loginRequest(
  endpoint: string,
  data: Record<string, string> | URLSearchParams | undefined
) {
  let headers = new Headers();
  headers.append("Content-Type", "application/x-www-form-urlencoded");

  let response = await request({
    endpoint: `${endpoint}/api/authentication`,
    method: "POST",
    body: data,
    headers,
  });
  return response.json();
}

export async function request({
  endpoint,
  method,
  headers,
  body,
  retry,
}: {
  endpoint: string;
  method: "POST" | "GET";
  headers?: any;
  body?: any;
  retry?: boolean;
}) {
  const url = "/" + endpoint;

  // console.log("request url: " + url);

  const resp = await fetch(url, {
    method,
    headers,
    body,
    redirect: "follow",
  });

  if (resp.ok) {
    return resp;
  }

  // wenn es user cedentials, aber keine sessionid gibt, wird einmal versucht sich mit den gespeicherten Daten einzuloggen
  if (resp.status === 401 && !retry) {
    const instance = encryptStorage.getItem("instance");
    const username = encryptStorage.getItem("username");
    const password = encryptStorage.getItem("password");
    if (instance && username && password) {
      let retryHeaders = new Headers();
      retryHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      const body = new URLSearchParams({
        username: username,
        password: password,
      });
      const authRequest: any = await request({
        endpoint: `${instance}/api/authentication`,
        method: "POST",
        body,
        headers: retryHeaders,
        retry: true,
      });
      console.log(authRequest);

      const retryResp: any = await request({
        endpoint,
        method,
      });

      return retryResp;
    }
  }

  return resp;
}
