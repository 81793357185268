import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { encryptStorage } from "../utils/storage";

const Logout: React.FC = (event: any) => {
  let navigate = useNavigate();

  useEffect(() => {
    encryptStorage.clear();
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_BASEPATH}login`;
    }, 1000);
  }, [navigate]);

  return (
    <Container className="p-5 mb-4 bg-light rounded-3">
      <h1 className="header">Logout</h1>
      <p>Du wurdest ausgeloggt.</p>
    </Container>
  );
};

export default Logout;
