import React, { useState } from "react";
import {
  Nav,
  Navbar,
  ThemeProvider,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Container from "react-bootstrap/Container";
import EventList from "./pages/EventList";
import Logout from "./pages/Logout";
import Login from "./pages/Login";
import EventDetail from "./pages/EventDetail";
import { Message } from "./data/types";
import Info from "./pages/Info";
import { encryptStorage } from "./utils/storage";

const App: React.FC = () => {
  const [responseMessage, setResponseMessage] = useState<Message | undefined>(
    undefined
  );

  return (
    <BrowserRouter>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        {responseMessage?.error ? (
          <ToastContainer className="p-3" position="top-center">
            <Toast
              show={responseMessage.show}
              onClose={() => setResponseMessage(undefined)}
              animation
              autohide
              bg={responseMessage.error ? "danger" : "success"}
              delay={2000}
            >
              <Toast.Header>
                <strong className="me-auto">{responseMessage.title}</strong>
              </Toast.Header>
              <Toast.Body>{responseMessage.message}</Toast.Body>
            </Toast>
          </ToastContainer>
        ) : null}

        {encryptStorage.getItem("instance") ? (
          <Navbar collapseOnSelect expand="lg" variant="light">
            <Container>
              <Navbar.Brand href={`${process.env.REACT_APP_BASEPATH}`}>
                co*pilot
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />

              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav activeKey={`${process.env.REACT_APP_BASEPATH}`}>
                  <Nav.Item>
                    <Nav.Link href={`${process.env.REACT_APP_BASEPATH}`}>
                      Events
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href={`${process.env.REACT_APP_BASEPATH}info`}>
                      Info
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href={`${process.env.REACT_APP_BASEPATH}logout`}>
                      Abmelden
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        ) : null}

        <Container>
          <Routes>
            <Route
              path={`${process.env.REACT_APP_BASEPATH}`}
              element={
                // encryptStorage.getItem("instance") && detailView ? (
                //   <EventDetail setResponseMessage={setResponseMessage} />
                // ) :
                encryptStorage.getItem("instance") ? (
                  <EventList setResponseMessage={setResponseMessage} />
                ) : (
                  <Login setResponseMessage={setResponseMessage} />
                )
              }
            />
            <Route
              path={`${process.env.REACT_APP_BASEPATH}event/:id`}
              element={<EventDetail setResponseMessage={setResponseMessage} />}
            />
            <Route
              path={`${process.env.REACT_APP_BASEPATH}logout`}
              element={<Logout />}
            />
            <Route
              path={`${process.env.REACT_APP_BASEPATH}info`}
              element={<Info />}
            />
            <Route
              path={`${process.env.REACT_APP_BASEPATH}login`}
              element={<Login setResponseMessage={setResponseMessage} />}
            />
          </Routes>
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
