import { Button } from "react-bootstrap";
import { Address } from "../data/types";

export const AddressBlock: React.FC<{
  label: string;
  address: Address;
}> = ({ label, address }) => {
  return (
    <div>
      <h6>{label}</h6>
      <div>{address?.street}</div>
      <div>{address?.addon}</div>
      <div>
        {address?.country ? address?.country.toUpperCase() : "DE"}{" "}
        {address?.zipcode} {address?.city}
      </div>
      {navigator.onLine ? (
        <div className="mb-2 mt-2">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.de/maps?q=${label} ${address?.street} ${
              address?.zipcode
            } ${address?.city} ${(address?.country ?? "DE").toUpperCase()}`}
          >
            <Button variant="secondary" size="sm">
              Anfahrt
            </Button>
          </a>
        </div>
      ) : null}
    </div>
  );
};
