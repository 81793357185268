import { Card } from "react-bootstrap";
import { Option } from "../data/types";
import { Communications } from "./Communications";

export const Contact: React.FC<{
  title: string;
  contact: {
    company: string;
    firstName: string;
    lastName: string;
    options: Option[];
  };
}> = ({ title, contact }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {contact?.company}
        </Card.Subtitle>

        {contact?.lastName || contact?.firstName ? (
          <div>
            {contact?.lastName}
            {contact?.firstName ? `, ${contact?.firstName}` : null}
          </div>
        ) : null}
        <div>
          {contact?.options && contact?.options?.length > 0 ? (
            <Communications communications={contact?.options} />
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
};
